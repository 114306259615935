<template>
  <div id="tag_page">
    <Layout :resize="true" :edit="false" :splits="tree">
      <Pane class="split-layout-custom-style">
        <div class="tag">
          <div class="p-2 tag__header-box">
            <h2 class="tag__header">
              Searched Tag: {{ this.$route.params.name }}
            </h2>
            <hr>
          </div>
          <div
            v-if="$store.state.tagContent.noteData.length > 0"
            class="noteData content-box"
          >
            <div
              :key="item._id"
              v-for="item in $store.state.tagContent.noteData"
              class="note-item content"
            >
              <div class="content__badge">
                <i name="notepad" class="content__badge__icon"/>
                <!-- <span class="content__badge__title">Note</span> -->
              </div>
              <h3 class="outer-tag-div content__heading">
                {{ item.value.note.substring(0, 30) }}
              </h3>
              <p class="content__description">{{ item.value.note.substring(0, 100) }}</p>
              <!-- <hr /> -->
              <div class="content__footer">
                <div class="content__author">
                  <span class="content__author--primary">@{{item.actor.username}}</span>
                  <span class="content__author--secondary">{{item.actor.email}}</span>
                </div>
                <span class="content__timestamp">{{timesAgo(item.value.timestamp)}}</span>
              </div>
            </div>
          </div>
          <div
            v-if="$store.state.tagContent.graphData.length > 0"
            class="graphData content-box"
          >
            <div
              :key="item._id"
              v-for="item in $store.state.tagContent.graphData"
              class="note-item content"
            >
              <div class="content__badge">
                <i class="content__badge__icon box-icon bx bx-git-branch"/>
                <!-- <span class="content__badge__title">Graph</span> -->
              </div>
              <h3 class="outer-tag-div content__heading">
                {{ item.g6_data.graph_name }}
              </h3>
              <p class="content__description">{{ item.g6_data.graph_type[0] }}</p>
              <!-- <hr /> -->
              <div class="content__footer">
                <div class="content__author">
                  <span class="content__author--primary">@{{item.actor.username}}</span>
                  <!-- <span class="content__author--secondary">{{item.actor.email}}</span> -->
                </div>
                <span class="content__timestamp">{{timesAgo(item.timestamp.machineReadable)}}</span>
              </div>
            </div>
          </div>
        </div>
      </Pane>
      <Pane class="split-layout-custom-style"> Operation View </Pane>
    </Layout>
  </div>
</template>

<script>
import { Layout, Pane } from 'vue-split-layout';
import moment from 'moment';

export default {
  data() {
    return {
      tree: {
        dir: 'horizontal', // Left | Right
        // Other split
        first: 0,
        second: 1,
        split: '35%',
      },
    };
  },
  components: {
    Layout,
    Pane,
  },
  methods: {
    timesAgo(date) {
      if (date) return moment(date).fromNow();
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/abstracts/variables';
#tag_page {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.split-layout-custom-style {
  border-radius: 5px;
  overflow-x: hidden !important;
}

.tag {
  padding: 1rem !important;
  color: $gray;
  cursor: pointer;
  position: relative;

  &__header-box {
    margin-bottom: 1rem;
  }

  &__header {
    color: $black;
  }

  .content {
    // background: rgba($color-grey-2, .6);
    // border: 1px solid rgba($color-grey-1, .4);
    border: 1px solid rgba(0,0,0,.125);
    padding: 1.5rem;
    margin-bottom: 0.5rem;
    position: relative;

    &__heading {
      color: $edvantaBlue;
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: .5rem;
    }

    &__author {
      color: $black;
      font-style: italic;
      display: flex;
      flex-direction: column;
      align-items: left;

      &--primary {
        font-size: 1.4rem;
      }

      &--secondary {
        font-size: .8rem;
      }
    }

    &__badge {
      position: absolute;
      display: flex;
      align-items: center;
      fill: gray;
      // color: $gray;
      bottom: .5rem;
      right: 1.5rem;
      padding: .1rem .3rem;
    }

    &:hover {
      background: rgba($secondaryBlue, .2);
    }
  }
}
</style>
