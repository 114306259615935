<template>
    <div>
      <!-- {{$store.state.singleNote}} -->

        <NoteDetails :noteId="$route.params.id" :note="$store.state.singleNote" :showAll="true"/>
          <hr>
        <div class="d-flex px-2">
          <p style="font-weight: bold;">Tags:</p>
          <p class="mx-2" v-for="tag in $store.state.singleNote.tags" :key="tag.id">
            <a :href="'/tag/' + tag.name">
            #{{tag.name}}
            </a>
          </p>
        </div>
    </div>
</template>

<script>
import NoteDetails from '@/common/widgets/NotesLibraryWidget/NoteDetails.vue';

export default {
  components: {
    NoteDetails,
  },
  data() {
    return {
      noteId: '',
      note: {},
    };
  },
  async mounted() {
    const { id } = this.$route.params;
    this.noteId = id;
    const note = await this.$store.dispatch('getNote', this.noteId);
    this.note = note;
  },
};
</script>

<style lang="scss" scoped>
</style>
